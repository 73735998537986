@import 'helpers';

:root {
  --primary-color: #2e7d32;
  --separator-color: #E6E7E6;
  --card-color: #F5F9F5;
  --footer-color: #F3F3F3;
  --footer-rights-color: #E6E7E6;
  --text-description: #666D66;

  --header-height: 70px;

  // margins
  --margin-xs: 8px;
  --margin-sm: 16px;
  --margin-md: 24px;
  --margin-lg: 32px;
  --margin-xl: 48px;
  --margin-xxl: 64px;

  --container-padding-mobile: 24px;
  --container-padding-portrait: 64px;
  --container-padding-landscape: 72px;
  --container-padding-desktopSmall: 120px;
}

html {
  scroll-behavior: smooth;
  font-size: 16px;
}

html,
body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  font-weight: normal;
}

body {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  overflow-x: hidden;
}

main {
  display: flex;
  flex-direction: column;
  margin-top: var(--header-height);
  flex-grow: 1;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none;
}

a {
  color: inherit;
  text-decoration: none;
  font-family: inherit;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

pre {
  margin: 0;
}

ul,
li {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

button {
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  font-family: inherit;
  text-align: inherit;
  cursor: pointer;
}

figure {
  margin: initial;
  padding: initial;
}


// now this sets the width and horizontal paddings,
// any change to this rule must be followed be regression testing of the whole app
.container {
  width: 100%;

  @include media(mobile) {
    padding-left: var(--container-padding-mobile) !important;
    padding-right: var(--container-padding-mobile) !important;
  }

  @include media(portrait) {
    padding-left: var(--container-padding-portrait) !important;
    padding-right: var(--container-padding-portrait) !important;
  }

  @include media(landscape) {
    padding-left: var(--container-padding-landscape) !important;
    padding-right: var(--container-padding-landscape) !important;
  }

  @include media(desktopSmall) {
    padding-left: var(--container-padding-desktopSmall) !important;
    padding-right: var(--container-padding-desktopSmall) !important;
  }

  @include media(desktop) {
    width: 1040px;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  @include media(desktopBig) {
    width: 1280px;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  @include media(desktopHuge) {
    width: 1280px;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.primary-text {
  color: var(--primary-color);
}

.capitalize-first-letter {
  display: block;

  &:first-letter {
    text-transform: capitalize;
  }
}

.header-container {
  top: 0;
  z-index: 2;
  width: 100%;
  display: flex;
  align-items: center;
  position: fixed;
  background-color: white;
  padding: var(--margin-sm);
  height: var(--header-height);
  justify-content: space-between;
  border-bottom: 1px solid var(--separator-color);
}

.avatar {
  margin-right: var(--margin-sm);
  border-radius: 50%;
  transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  -webkit-transform: scale(1, 1);
  max-width: 90px;
  max-height: 90px;
}

.text-description-ellipsis {
  @include multi-line-ellipsis($lines-to-show: 3);
}

.not-found-image {
  background-image: url('../../public/media/ezgif.com-optimize.gif');
  background-repeat: no-repeat;
  height: 400px;
  width: 100%;
  background-position: center;
}

.grecaptcha-badge { visibility: hidden; }

@include font-face("Roboto-Regular", "/fonts/Roboto/Roboto-Regular");
@include font-face("Roboto-Regular", "/fonts/Roboto/Roboto-Bold", 700);
